<div style="overflow: hidden;">
    
    <div class="top-hader" style="width: 100%;height: 100vh;min-height: 650px;">
        <div class="header">
            <img src="assets/header/combo1.png" alt="" class="combo1">
            <img src="assets/header/combo2.png" alt="" class="combo2">
            <div class="items-center">
                <img src="assets/header/logo.png" alt="" />
                <h5>
                    Prueba la cocción ahumada al cilindro
                    con papas fritas y ensalada
                </h5>
                <p>¡Carnes al cilindro, saltados y chaufas!</p>
                <p><strong>¿Mochilero, cuál eliges?</strong></p>
                <ion-app>
                    <button class="ion-activatable ripple-parent" (click)="comenzar()">
                        <strong>Comenzar mi pedido</strong>
                        <ion-ripple-effect></ion-ripple-effect>
                    </button>
                </ion-app>
            </div>
            <img src="assets/header/combo3.png" alt="" class="combo3">
            <img src="assets/header/combo4.png" alt="" class="combo4">
        </div>
    </div>
    <div class="video-top" style="width: 100%; height: 100vh;overflow: hidden;margin-top: -100vh;min-height: 650px;">
        <video autoplay loop muted>
            <source src="assets/header/fondo-header.mp4" type="video/mp4">
            <!-- <source src="assets/header/home.mp4" type="video/mp4"> -->
                Your browser does not support the video tag.
        </video>
    </div>
    <div class="emblematico">
        <div class="box-global">
            <h5>
                La Mochila del chef es un <br />
                restaurante emblemático
            </h5>
            <p style="margin-bottom: 40px;">¡Tenemos la receta secreta que le gusta a todos!</p>
            <div class="row-options">
                <div class="box">
                    <img src="assets/emblematico/Ellipse_1.png" alt="" />
                    <p><strong>Delivery</strong></p>
                    <p class="text">Autos, motorizados, y bicicletas para una entrega 100% responsable.</p>
                </div>
                <div class="box">
                    <img src="assets/emblematico/Ellipse_2.png" alt="" />
                    <p><strong>Somos expertos</strong></p>
                    <p class="text">Hicimos las carnes al cilindro más grandes del mundo. Conocemos todo sobre cilindro.</p>
                </div>
                <div class="box">
                    <img src="assets/emblematico/Ellipse_5.png" alt="" />               
                    <p><strong>Todas las carnes</strong></p>
                    <p class="text">Tenemos en la carta variedad de platos personales y combos para toda la familia.</p>
                </div>
                <div class="box">
                    <img src="assets/emblematico/Ellipse_4.png" alt="" />
                    <p><strong>Experiencia</strong></p>
                    <p class="text">2 años en MISTURA (1) Feria del Hogar Ruta Gastronómica (Muni. de Lima) Usa-Corea y más.</p>
                </div>
                <div class="box">
                    <img src="assets/emblematico/Ellipse_3.png" alt="" />
                    <p><strong>Protocolos</strong></p>
                    <p class="text">Operatividad protegida bajo el mayor de los cuidados de manipulación.</p>
                </div>
            </div>
            <ion-content class="content-slide-emblematico no-scroll">
                <ion-slides pager="true" [options]="slideOpts">
                    <ion-slide>
                        <div class="box">
                            <img src="assets/emblematico/Ellipse_1.png" alt="" />
                            <p><strong>Delivery</strong></p>
                            <p class="text">Autos, motorizados, y bicicletas para una entrega 100% responsable.</p>
                        </div>
                    </ion-slide>
                    <ion-slide>
                        <div class="box">
                            <img src="assets/emblematico/Ellipse_2.png" alt="" />
                            <p><strong>Somos expertos</strong></p>
                            <p class="text">Hicimos las carnes al cilindro más grandes del mundo. Conocemos todo sobre cilindro.</p>
                        </div>
                    </ion-slide>
                    <ion-slide>
                        <div class="box">
                            <img src="assets/emblematico/Ellipse_5.png" alt="" />
                            
                            <p><strong>Todas las carnes</strong></p>
                            <p class="text">Tenemos en la carta variedad de platos personales y combos para toda la familia.</p>
                        </div>
                    </ion-slide>
                    <ion-slide>
                        <div class="box">
                            <img src="assets/emblematico/Ellipse_4.png" alt="" />
                            <p><strong>Experiencia</strong></p>
                            <p class="text">2 años en MISTURA (1) Feria del Hogar Ruta Gastronómica (Muni. de Lima) Usa-Corea y más.</p>
                        </div>
                    </ion-slide>
                    <ion-slide>
                        <div class="box">
                            <img src="assets/emblematico/Ellipse_3.png" alt="" />
                            <p><strong>Protocolos</strong></p>
                            <p class="text">Operatividad protegida bajo el mayor de los cuidados de manipulación.</p>
                        </div>
                    </ion-slide>
                </ion-slides>
            </ion-content>
            <p class="reinventamos">Reinventamos nuestro servicio de entrega. Vive la experiencia de comer las carnes al cilindro más grandes del mundo...</p>
        </div>
    </div>
    <div class="video-text">
        <div class="video">
            <iframe src="https://www.youtube.com/embed/r1dmk867BT8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="description">
            <p>
                “Esperamos en cada comensal, que la experiencia de comer con nosotros sea feliz y que se comparta con la sociedad.”
                <br />
                <br />
                <strong>#lamochiladelchef</strong>
            </p>
        </div>
    </div>
    <div class="space-red"></div>
    <div class="map-text">
        <p class="map-text-p" style="font-size: 26px;width: 70%;margin: auto;font-weight: 500;margin-bottom: 90px;">
            Llevamos nuestro sabor ahumado al cilindro con los
            mejores acompañamientos a la mesa de tu hogar
            <br />
            <br />
            <strong>Mochilero selecciona la forma de entrega</strong>
        </p>
        <div class="buttons">
            <button [ngClass]="{'active': deliveryOption == 'delivery'}"(click)="selectOptionDelivery('delivery')">
                <div class="cirlce">
                    <div class="circle-active"></div>
                </div>
                <img src="assets/mapaIcon/moto.png" *ngIf="deliveryOption != 'delivery'">
                <img src="assets/mapaIcon/motoActive.png" *ngIf="deliveryOption == 'delivery'">
                Delivery
            </button>
            <button [ngClass]="{'active': deliveryOption == 'recoger'}" (click)="selectOptionDelivery('recoger')">
                <div class="cirlce">
                    <div class="circle-active"></div>
                </div>
                <img src="assets/mapaIcon/tachocasa.png" *ngIf="deliveryOption != 'recoger'">
                <img src="assets/mapaIcon/tachocasaActive.png" *ngIf="deliveryOption == 'recoger'">
                Recogerlo
            </button>
        </div>
        <div class="content-maps" *ngIf="deliveryOption == 'delivery'">
            <div>
                <label>Distritos disponibles:</label>
                <select [(ngModel)]="distrito" (change)="changeValue()">
                    <option disabled selected>Selecciona una opción</option>
                    <option *ngFor="let distritosList of distritos" [value]="distritosList.nombre" >{{distritosList.nombre}} (costo: S/{{distritosList.delivery}}.00)</option>
                </select>
            </div>
            <div *ngIf="distrito != 'Selecciona una opción'">
                <input type="text" placeholder="Dirección de domicilio" [(ngModel)]="direccion" (ngModelChange)="valueDomicilio($event)">
            </div>
            <button *ngIf="distrito != 'Selecciona una opción'" class="seguirPedido" (click)="seguirPedido()">Seguir con mi pedido</button>
            <iframe src="https://www.google.com/maps/d/embed?mid=1DngBvDX2HUn0oMryqKhFw7rUfAMcBe1B"></iframe>
        </div>
        <div class="locales" *ngIf="deliveryOption == 'recoger'">
            <div class="content-central">
                <p class="text-description">Te esperamos con tu pedido listo en el restaurante de tu preferencia <strong>¿En que Mochila quieres recogerlo?</strong> </p>
                <div class="row-list" (click)="recoger(localList)" *ngFor="let localList of locales" [ngClass]="{'active': localList == localSelect}">
                    <div class="circle">
                        <div class="circle-active"></div>
                    </div>
                    <p>{{localList}}</p>
                </div>
            </div>
        </div>
        
        <!-- <div id="map"></div> -->
        
    </div>

    <div class="select-option">
        <h3>¡Elige la mejor opción!</h3>
        <div class="row-categorias">
            <button class="box" *ngFor="let tag of categori" (click)="categoriBtn(tag)">
                <img [src]="tag.img" alt="" />
                <p>{{tag.name}}</p>
            </button>
        </div>
        <div class="platos-filter-categori">
            <ng-container *ngFor="let list of camboList">
                <div class="box-combo" *ngIf="list.categoria === categoriSelect">
                    <img [src]="list.img" alt="" />
                    <div class="row-name-preci">
                        <p style="width: 138px;height: 50px;">
                            <strong>{{list.titulo}}</strong><br />
                            {{list.cantidad_personas}}
                        </p>
                        <p><strong>S/ {{list.precio}}</strong></p>
                    </div>
                    <p class="description">
                        {{list.descripcion}}
                    </p>
                    <div class="row-quantity-button">
                        <div class="row-suma-resta">
                            <button class="resta" (click)="comida(list, 0)">-<ion-ripple-effect></ion-ripple-effect></button>
                            {{list.cantidad}}
                            <button class="suma" (click)="comida(list, 1)">+<ion-ripple-effect></ion-ripple-effect></button>
                        </div>
                        <ion-app>
                            <button class="ion-activatable ripple-parent addBtn addBtn-active" [disabled]="list.cantidad == 0" (click)="addProductBtn(list)">
                                Añadir
                                <ion-ripple-effect type="unbounded"></ion-ripple-effect>
                            </button>
                        </ion-app>
                    </div>
                </div>
            </ng-container>
        </div>
        <ion-content class="content-slide-combos no-scroll">
            <ion-slides pager="true" [options]="slideOpts2">
                <ng-container *ngFor="let list of camboList">
                    <ion-slide  *ngIf="list.categoria === categoriSelect">
                        <div class="box-combo">
                            <img [src]="list.img" alt="" />
                            <div class="row-name-preci">
                                <p>
                                    <strong>{{list.titulo}}</strong><br />
                                    {{list.cantidad_personas}}
                                </p>
                                <p><strong>S/ {{list.precio}}</strong></p>
                            </div>
                            <p class="description">
                                {{list.descripcion}}
                            </p>
                            <div class="row-quantity-button">
                                <div class="row-suma-resta">
                                    <button class="resta" (click)="comida(list, 0)">-<ion-ripple-effect></ion-ripple-effect></button>
                                    {{list.cantidad}}
                                    <button class="suma" (click)="comida(list, 1)">+<ion-ripple-effect></ion-ripple-effect></button>
                                </div>
                                <ion-app>
                                    <button class="ion-activatable ripple-parent addBtn addBtn-active" [disabled]="list.cantidad == 0" (click)="addProductBtn(list)">
                                        Añadir
                                        <ion-ripple-effect type="unbounded"></ion-ripple-effect>
                                    </button>
                                </ion-app>
                            </div>
                        </div>
                    </ion-slide>
                </ng-container>
            </ion-slides>
        </ion-content>
    </div>
    <div class="content-pedido">
        <h3>
            Siente el sabor y vive la experiencia 
            del cilindro “Haz tu pedido”
        </h3>
        <br>
        <div class="box-tu-pedido">
            <p class="has-pedido">Has pedido:</p>
            <ng-container *ngFor="let list of camboList">
                <div class="card-pedido" *ngIf="list.add">
                    <img [src]="list.img" alt="" />
                    <div class="box-row">
                        <div class="row-title-button">
                            <p>{{list.titulo}} <br> (S/ {{list.precio}}.00)</p>
                            <button (click)="removeItem(list)">
                                <img src="assets/delete.png" alt="" />
                            </button>
                        </div>
                        <div class="row-cantidad-precio">
                            <p><strong>X{{list.cantidad}}</strong></p>
                            <p><span>S/{{list.precio * list.cantidad}}</span></p>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <form action="" class="formulario-global" [formGroup]="form">
            <div class="direccion">
                <p>Dirección de entrega</p>
                <ng-container *ngIf="deliveryOption == 'delivery'">
                    <input type="text" placeholder="Distrito"  formControlName="distrito" readonly (click)="comenzar()"/>
                    <input type="text" placeholder="Dirección de domicilio"  formControlName="domicilio" readonly (click)="comenzar()"/>
                    <input type="text" placeholder="Detalle la ubicación"  formControlName="detalle"/>
                </ng-container>
                <ng-container *ngIf="deliveryOption == 'recoger'">
                    <input type="text" placeholder="Distrito"  formControlName="distrito" readonly (click)="comenzar()"/>
                </ng-container>
            </div>

            <div class="datos-personales">
                <p>¿Cómo te llamamos?</p>
                <input type="text" placeholder="Nombre y Apellido" formControlName="nombres" />
                <input type="tel" placeholder="Número de WhatsApp" formControlName="numero"/>
                <input type="text" placeholder="Correo electrónico" formControlName="correo"/>
            </div>

            <div class="datos-personales">
                <p>¿Deseas especificar alguna observación acerca de tu pedido?</p>
                <textarea formControlName="observaciones" type="textarea" placeholder="Puedes indicar a que hora recoges tu pedido, si deseas extra ají, cubiertos, alguna obervación de quien recibe el pedido u otros datos "></textarea>
            </div>

            <div class="pagar">
                <p>¿Cómo quieres pagar?</p>
                <div class="content-options">
                    <ng-container *ngFor="let list of metodoPagoList">
                        <div class="row-option" (click)="selectMetodoPago(list)">
                            <div>
                                <img [src]="list.img" alt="">
                                <p>{{list.nombre}}</p>
                            </div>
                            <div class="circle" [ngClass]="{'activeCircle': list.select}">
                                <div></div>
                            </div>
                        </div>
                        <div class="mensaje" [ngClass]="{'mesage-active': list.select}">
                            <img src="assets/mesage.png" alt="">
                            <p>{{list.mensaje}}</p>
                        </div>
                    </ng-container>
                    
                </div>
            </div>
        </form>
    </div>

    <div class="total">
        <div>
            <p>Sub-Total</p>
            <p>S/ {{finishPreci}}.00</p>
        </div>
        <div *ngIf="deliveryOption == 'delivery'">
            <p>Delivery</p>
            <p>S/ {{montoCostoDelivery}}.00</p>
        </div>
        <div class="final">
            <p>Total del pedido</p>
            <p *ngIf="deliveryOption == 'delivery'" >S/ {{finishPreci + montoCostoDelivery}}.00</p>
            <p *ngIf="deliveryOption == 'recoger'" >S/ {{finishPreci}}.00</p>
        </div>
    </div>
    <div class="row-fininsh-button">
        <ion-app>
            <button class="ion-activatable ripple-parent" (click)="sendPedido()">
                <ion-ripple-effect type="unbounded"></ion-ripple-effect>
                <strong>Finalizar mi pedido</strong>
               
            </button>
        </ion-app>
    </div>
    

    <div class="pedidoOK" *ngIf="complete">
        <div class="content-center">
            <img src="assets/header/logo.png" alt="" />
            <p>Recibimos tu pedido correctamente. Confírmalo por WhatsApp dándole click al botón.  </p>
            <a [href]="'https://wa.me/+51960699474?text=' + stringWtspp" target="_blank"><img src="assets/whatsapp.png" alt=""> Ir a WhatsApp</a>
            <button (click)="resetPedido()">Hacer un nuevo pedido.</button>
        </div>
    </div>
</div>
