import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class JsonService {

  constructor(private http: HttpClient) { }

  getDatosCombos(): Observable<any> {
    const url = 'assets/platos.json' ;
    return this.http.get<any>(url);
  }

  getDatosCategori(): Observable<any> {
    const url = 'assets/categoria.json' ;
    return this.http.get<any>(url);
  }
}
