import { Component, OnInit } from '@angular/core';
import * as XLSX from "ts-xlsx";
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  file:File;
  arrayBuffer:any;
  downloadJsonHref:any; 
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  incomingfile(event) {
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = e => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      var hola = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      for (let i = 0; i < hola.length; i++) {
        hola[i]['add'] = false;
        hola[i]['cantidad'] = 0       
      }
      console.log(hola)
      var theJSON = JSON.stringify(hola);
      var uri = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(theJSON));
      this.downloadJsonHref = uri;
    };
    fileReader.readAsArrayBuffer(this.file);

  }
}
