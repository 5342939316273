import { Component, OnInit } from '@angular/core';
import MapTypeId = google.maps.MapTypeId;
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import {Service} from '../../services/service.ts.service';
import {JsonService} from '../../services/json.service'
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { AlertController } from '@ionic/angular';
import * as $ from 'jquery';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public categoriSelect = 'COMBOS';
  public categori:any;
  public camboList:any;
  public addProduct = [];
  public metodoPagoList = [
    {
      nombre: 'YAPE',
      img: 'assets/metodos-pago/yape.png',
      mensaje: 'Al finalizar tu pedido, te indicaremos por WhatsApp el número en el que harás tu pago.',
      select: false
    },
    {
      nombre: 'Transferencia',
      img: 'assets/metodos-pago/pagolink.png',
      mensaje: 'Al finalizar tu pedido, te enviaremos por WhatsApp el número de cuenta para el deposito',
      select: false
    },
    {
      nombre: 'POS',
      img: 'assets/metodos-pago/pos.png',
      mensaje: 'Al finalizar tu pedido, nos comunicaremos por WhatsApp para que nos des detalles de la tarjeta con la que deseas pagar',
      select: false
    },
    {
      nombre: 'Efectivo',
      img: 'assets/metodos-pago/billete.png',
      mensaje: 'Al finalizar tu pedido, nos comunicaremos contigo por WhatsApp para conocer el monto con el que harás tu pago',
      select: false
    },
  ]

  public distritos = [
    {
      nombre: 'Breña',
      delivery: 5
    },
    {
      nombre: 'Pueblo Libre',
      delivery: 5
    },
    {
      nombre: 'Jesús María',
      delivery: 3
    },
    {
      nombre: 'CENTRO HISTORICO',
      delivery: 5
    },
    {
      nombre: 'El Rímac',
      delivery: 5
    },
    {
      nombre: 'Barrios altos',
      delivery: 2.00
    },
    {
      nombre: 'El Agustino',
      delivery: 4.00
    },
    {
      nombre: 'La Victoria',
      delivery: 5.00
    }
  ]

  stringWtspp = ''
  complete = false;
  public locales = [
    "Av. Husares de Junín 249, Jesús María",
    "Calle Centro Escolar 226, Barrios Altos",
    "Calle Atahualpa 1810 Iquitos - Loreto"
  ]
  public localSelect = 'Av. Husares de Junín 249, Jesús María'

  form: FormGroup;
  public finishPreci = 0;
  distrito = 'Selecciona una opción';
  deliveryOption = 'delivery';
  montoCostoDelivery = 0;






  
  file:File;
  preAdd = [];
  arrayBuffer:any;
  sendDataService = {
    pedido : [],
    distrito: '',
    direccion: '',
    detalle: '',
    nombre_apellido: '',
    numero:'',
    correo:'',
    observaciones:'',
    metodo_pago: ''
  }

  constructor(private sanitizer: DomSanitizer,
              public service:Service,
              public jsonService:JsonService,
              private fb: FormBuilder,
              public alertController: AlertController) { }
  center= {lat: -12.047107207170466, lng: -77.04144627943656}
  zoom = 15;
  display?: google.maps.LatLngLiteral;  
  downloadJsonHref:any;
  holaa = {
    categoria: 1,
    descripción: 1,
    imagen: 1,
    nombre: 1,
    precio: 1
  }
  slideOpts = {
    initialSlide: 0,
    speed: 400
  };

  slideOpts2 = {
    initialSlide: 0,
    speed: 400
  };

  direccion = '';

  ngOnInit(): void {
    this.form = this.fb.group({
      distrito: new FormControl('',{
        validators: Validators.compose([
          Validators.required,
        ])
      }),
      domicilio: new FormControl('',{
        validators: Validators.compose([
          Validators.required,
        ])
      }),
      detalle: new FormControl(''),
      nombres: new FormControl('',{
        validators: Validators.compose([
          Validators.required,
        ])
      }),
      numero: new FormControl('',{
        validators: Validators.compose([
          Validators.required,
          Validators.minLength(9)
        ])
      }),
      correo: new FormControl('',{
        validators: Validators.compose([
          Validators.required,
          Validators.email
        ])
      }),
      observaciones: new FormControl('')
    });
    this.jsonService.getDatosCombos().subscribe(Response => {
      this.camboList = Response;
    })
    this.jsonService.getDatosCategori().subscribe(Response => {
      this.categori = Response;
    })
    // var theJSON = JSON.stringify(this.holaa);
    // var uri = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(theJSON));
    // this.downloadJsonHref = uri;
    // const map = new google.maps.Map(
    //   document.getElementById("map") as HTMLElement,
    //   {
    //     zoom: 15,
    //     center: {lat: -12.047107207170466, lng: -77.04144627943656},
    //     mapTypeId: "terrain",
    //   })
    //   const triangleCoords = [
    //     {lat: -12.047107207170466, lng: -77.04144627943656},
    //     { lat: -12.073611654442944, lng: -77.0154452290398 },
    //     {lat: -12.082394582724193, lng: -77.0499118682026}
    //   ];
    //   const myLatLng = { lat: -12.05951471841401, lng: -77.07159341402372 };
    //   // { lat: -12.061750319492456, lng: -77.03671847334789 };
    //   // -12.05951471841401, -77.07159341402372
    //   // Construct the polygon.
    //   const bermudaTriangle = new google.maps.Polygon({
    //     paths: triangleCoords,
    //     strokeColor: "#FF0000",
    //     strokeOpacity: 0.8,
    //     strokeWeight: 2,
    //     fillColor: "#FF0000",
    //     fillOpacity: 0.35,
    //   });
    //   bermudaTriangle.setMap(map);

    //   const marked = new google.maps.Marker({
    //     position: myLatLng,
    //     map,
    //     title: "Hello World!",
    //   });
    //   const bounds = map.getBounds();

    }
  
  changeValue() {
    for (let i = 0; i < this.distritos.length; i++) {
      if (this.distritos[i].nombre == this.distrito) {
        this.montoCostoDelivery = this.distritos[i].delivery;
      }
    }
    this.form.controls['distrito'].setValue(this.distrito)
  }
  valueDomicilio(event){
    this.form.controls['domicilio'].setValue(this.direccion)
  }

  seguirPedido() {
    var body = $("html, body");
      body.stop().animate({scrollTop: $('.header').height() + $('.emblematico').height() + $('.video-text').height() + $('.map-text').height() + 100}, 1000, 'swing');
  }

  selectOptionDelivery(value) {
    this.deliveryOption = value;
    this.form.controls['distrito'].setValue(this.localSelect)
  }

  recoger(value) {
    this.localSelect = value;
    this.form.controls['distrito'].setValue(this.localSelect)
  }

  

  comida(item, operacion) {
    if (operacion == 1) {
      item.cantidad++
    } else {
      if (item.cantidad > 0) {
        item.cantidad--        
      }
    }
    // for (let i = 0; i < this.camboList.length; i++) {
    //   if (this.camboList[i].add) {
    //     this.finishPreci = this.camboList[i].precio * this.camboList[i].cantidad
    //   }
    // }
    if (item.add) {
      this.sumaAllProductAdd();
    }
    
    console.log(item)
    console.log(this.camboList)
  }

  removeItem(item) {
    item.add = false;
    item.cantidad = 0;
    this.sumaAllProductAdd();
  }

  addProductBtn(item){
    item.add = true;
    this.sumaAllProductAdd()
    // this.service.getItems().subscribe(response => {
    //   console.log(response)
    // })
  }

  sumaAllProductAdd() {
    var helpCombos = [];
    for (let i = 0; i < this.camboList.length; i++) {
      if (this.camboList[i].add) {
        helpCombos.push(this.camboList[i].precio * this.camboList[i].cantidad)  
      }
    }
    var suma = 0;
    helpCombos.forEach (function(numero){
      suma += numero;
    });  
    this.finishPreci = suma
    console.log(this.finishPreci)
  }


  categoriBtn(categori) {
    this.categoriSelect = categori.name;
  }

  selectMetodoPago(item){
    for (let i = 0; i < this.metodoPagoList.length; i++) {
      this.metodoPagoList[i].select = false;    
    }
    item.select = true;
    this.sendDataService.metodo_pago = item.nombre;
  }

  sendPedido() {
    console.log(this.form.controls.correo)
    this.sendDataService.pedido = [];
    for (let i = 0; i < this.camboList.length; i++) {
      if (this.camboList[i].add) {
        this.sendDataService.pedido.push({
          nombre: this.camboList[i].titulo,
          cantidad: this.camboList[i].cantidad,
          precioXunidad: this.camboList[i].precio,
          precioTotal: this.camboList[i].precio * this.camboList[i].cantidad
        })
      }
    }

    if (this.sendDataService.pedido.length == 0) {
      this.presentAlertConfirm('Seleccione combo')
    } else {
      if (this.deliveryOption == 'delivery') {
        if (this.form.valid) {
          if (this.sendDataService.metodo_pago != '') {
            // this.service.create_NewStudent(this.sendDataService,'hola3');
            let pedidoString = [];
            for (let i = 0; i < this.sendDataService.pedido.length; i++) {
              pedidoString.push('( ' + this.sendDataService.pedido[i].cantidad + ' ) ' + this.sendDataService.pedido[i].nombre)
            }
            this.stringWtspp = 'Mi pedido *delivery* es: *NOMBRE:* ' + this.form.value.nombres + ', *DISTRITO:* ' + this.form.value.distrito + ', *DIRECCIÓN:* ' + this.form.value.domicilio + (this.form.value.detalle != '' ? ', *DETALLE:* ' + this.form.value.detalle : '') + ', *NUMERO:* ' + this.form.value.numero + (this.form.value.observaciones != '' ? ', *OBSERVACIONES:* ' + this.form.value.observaciones : '') + ', *PAGO:* ' + this.sendDataService.metodo_pago + ', *PEDIDO:* ' + '[ ' + pedidoString.join() + ' ]' + ', *PRECIO TOTAL:* S/'  + (this.finishPreci + this.montoCostoDelivery)
            this.complete = true;
          } else {
            this.presentAlertConfirm('Seleccione un metodo de pago')
          }
        } else {
          this.presentAlertConfirm('Complete todos los campos')
        }
      } else {
        if (this.form.controls.nombres.valid) {
          if (this.form.controls.numero.valid) {
            if (this.form.controls.correo.valid) {
              if (this.sendDataService.metodo_pago != '') {
                let pedidoString = [];
                for (let i = 0; i < this.sendDataService.pedido.length; i++) {
                  pedidoString.push(' ' + this.sendDataService.pedido[i].nombre + ' cantidad(' + this.sendDataService.pedido[i].cantidad + ')')
                }
                this.stringWtspp = 'Mi pedido para *recoger* es: *NOMBRE:* ' + this.form.value.nombres + ', *DISTRITO:* ' + this.form.value.distrito + ', *NUMERO:* ' + this.form.value.numero + (this.form.value.observaciones != '' ? ', *OBSERVACIONES:* ' + this.form.value.observaciones : '') + ', *PAGO:* ' + this.sendDataService.metodo_pago + ', *PEDIDO:* ' + '[ ' + pedidoString.join() + ' ]' + ', *PRECIO TOTAL:* S/'  + this.finishPreci
                this.complete = true;
                // this.service.create_NewStudent(this.sendDataService,'hola3');
              } else {
                this.presentAlertConfirm('Seleccione un metodo de pago')
              }
            } else {
              this.presentAlertConfirm('Completa el campo de correo')
            }
          } else {
            this.presentAlertConfirm('Completa el campo de numero')
          }
        } else {
          this.presentAlertConfirm('Completa el campo de nombre')
        }
      }
    }
    // for (let i = 0; i < this.camboList.length; i++) {
    //   if (this.camboList[i].add) {
    //     this.sendDataService.pedido.push({
    //       nombre: this.camboList[i].titulo,
    //       cantidad: this.camboList[i].cantidad,
    //       precioXunidad: this.camboList[i].precio,
    //       precioTotal: this.camboList[i].precio * this.camboList[i].cantidad
    //     })
    //   }
    //   this.sendDataService = {
    //     ...this.sendDataService,
    //     distrito: this.form.value.distrito,
    //     direccion: this.form.value.direccion,
    //     detalle: this.form.value.detalle,
    //     nombre_apellido: this.form.value.nombres,
    //     numero: this.form.value.numero,
    //     correo: this.form.value.correo,
    //     observaciones: this.form.value.observaciones,
    //   }
    // }
    // this.service.create_NewStudent(this.sendDataService,'hola3');
  }


  comenzar() {
    var body = $("html, body");
      body.stop().animate({scrollTop: $('.header').height() + $('.emblematico').height() + $('.video-text').height() + 100}, 1000, 'swing', function() { 
         console.log('finished')
      });
  }

  async presentAlertConfirm(msg) {
    const alert = await this.alertController.create({
      cssClass: 'alert-incomplete',
      header: 'Datos Incompletos',
      message: msg,
      buttons: [
         {
          text: 'Esta bien',
        }
      ]
    });

    await alert.present();
  }

  resetPedido() {
    window.location.reload(); 
  }
}
