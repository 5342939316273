import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component'
import { AdminComponent } from './components/admin/admin.component'
const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'user-administrador', component: AdminComponent}
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
